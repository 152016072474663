import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Interactive Maps" />
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">Award-Winning D3 Storymap</h3>
            <div>
              <p>
                2019 group winner of the NACIS Student Dynamic Mapping Competition. Dental desert map and data. Built with D3, Leaflet, and Mapbox. Infobox on mouseover.
              </p>
              <p className="project-wrapper__text-it">Careless Decay: Dental Deserts in the United States</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--hero"
              href="https://gerirosenberg.github.io/careless-decay/"
            >
              Link
            </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn text-color-main"
                href="https://github.com/gerirosenberg/careless-decay"
              >
                Source Code
              </a>
          </div>
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">D3 Map with Responsive Scatterplot</h3>
            <div>
              <p>
                D3 interactive map of D.C. region commuting data by census tracts. Responsive scatterplot maps commuting data by income. Infolabels on tract/dot mouseover.
              </p>
              <p className="project-wrapper__text-it">How the D.C. Region Gets to Work</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href="https://gerirosenberg.github.io/d3-coordinated-viz/"
            >
              Link
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn text-color-main"
              href="https://github.com/gerirosenberg/d3-coordinated-viz"
            >
              Source Code
            </a>
          </div>
        <div className="project-wrapper__text">
          <h3 className="project-wrapper__text-title">Leaflet Time Series Map with Filters</h3>
            <div>
              <p>
                Interactive Leaflet time series map of California wildfire data by county (2008-2016). Filter by fire size. Arrow buttons adjust year. Click fire for more popup details.
              </p>
              <p className="project-wrapper__text-it">California Burning</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href="https://gerirosenberg.github.io/california-burning/"
            >
              Link
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn text-color-main"
              href="https://github.com/gerirosenberg/california-burning"
            >
              Source Code
            </a>
          </div>
        </div>
        <div className="project-wrapper">
          <Title title="Print Maps and Data Analysis" />
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">Economic Analysis of Transit Benefits in Virginia</h3>
            <div>
              <p>
                Economic analysis of 41 transit agencies in Virginia, accounting for 175M annual ridership, $575M+ in payroll, and $480M+ in capital spending. Completed literature reviews, developed research questions, and designed qualitative/quantitative analyses in partnership with project manager.
              </p>
              <p className="project-wrapper__text-it">Economic Impacts of Public Transportation in the Commonwealth of Virginia</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href=""
            >
              Link
            </a>
          </div>
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">Analysis of Maryland's Annual Transportation Performance Measures</h3>
            <div>
              <p>
                Collected, segmented, and analyzed data for performance measures. Led QA/QC and developed static visualizations and maps. Developed policy copy.
              </p>
              <p className="project-wrapper__text-it">Maryland Department of Transportation 2020 Annual Attainment Report</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href="https://mdot.maryland.gov/tso/pages/Index.aspx?PageId=121"
            >
              Link
            </a>
          </div>
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">Analysis of Virginia's Biennial Transportation Performance Measures</h3>
            <div>
              <p>
                Collected, segmented, and analyzed data for performance measures. Led QA/QC and developed static visualizations and maps. Developed policy copy.
              </p>
              <p className="project-wrapper__text-it">Virginia Office of Intermodal Planning and Investment Biennial Report 2019</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href="http://oipi.virginia.gov/programs/performance-measures/default.asp"
            >
              Link
            </a>
          </div>
        </div>
        <div className="project-wrapper">
          <Title title="Policy Writing" />
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">Rural Affordable Housing Policy Toolkit</h3>
            <div>
              <p>
                Researched for and co-authored affordable housing toolkit for rural communities as part of U.S. Economic Development Administration project.
              </p>
              <p className="project-wrapper__text-it">Providing Well-Placed Affordable Housing in Rural Communities (2017)</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href="https://smartgrowthamerica.org/wp-content/uploads/2017/04/rural-toolkit_affordable-housing.pdf"
            >
              Link
            </a>
          </div>
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">Bus Rapid Transit Best Practices Guide</h3>
            <div>
              <p>
                Researched for and co-authored a Bus Rapid Transit design guide for residents, advocates, and policymakers in Montgomery County, MD.
              </p>
              <p className="project-wrapper__text-it">Best Practices in Rapid Transit System Design (2015)</p>
            </div>
              <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--project"
              href="https://www.smartergrowth.net/wp-content/uploads/2015/08/2015-BRT-best-practices-guide.pdf"
            >
              Link
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
