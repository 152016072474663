import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import { Link } from 'react-scroll';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <div className="about-wrapper__image">
              <AboutImg alt="profile picture" filename={img} />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="about-wrapper__info">
              <p className="about-wrapper__info-text">
                I'm a storyteller. I turn big globs of databases into something meaningful to humans. As a visual thinker, I bring a clear and well-balanced perspective to analyses and visualizations, whether print or <a href="https://gerirosenberg.github.io/careless-decay/">interactive</a>. I believe in equity for all communities, and I apply that to all of my work, from the research questions I design, to the data I collect, to the way I design my maps and charts. While still conveying the whole story and telling a clear narrative, I strive to make maps as simple as possible.
              </p>
              <p className="about-wrapper__info-text">
                I've worn many hats throughout my career, at various points becoming a <a href="https://todresources.org/blog/tod-peer-network-connects-2017-locus-leadership-summit/">coalition leader</a>, an <a href="https://smartgrowthamerica.org/amazing-place-greenville/">event planner</a>, an <a href="http://www.drpt.virginia.gov/media/3263/drpt-eit-in-va-executive-summary_final_112320.pdf">economist</a>, and a <a href="https://smartgrowthamerica.org/wp-content/uploads/2017/04/rural-toolkit_affordable-housing.pdf">rural affordable housing expert</a>. I believe teams with the most diverse backgrounds develop the most innovative solutions.
              </p>
              <p className="about-wrapper__info-text">
                I'm also a cyclist, runner, and long-distance backpacker. My personal battery runs on big goals, like <a href="http://www.instagram.com/gerihikes">thru-hiking</a> a 500-mile mountain wilderness trail and running my first half-marathon in the middle of a pandemic. From writing minutely-detailed workplans to setting and sticking to every deadline, I apply this goal-oriented lifestyle to all parts of my work.
              </p>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn cta-btn--resume"
                  href="http://resume.gisgeri.com/resume.pdf"
                >
                  Resume
                </a>    
                <Link to="projects" smooth duration={500} className="cta-btn cta-btn--resume">
                  Projects
                </Link>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn cta-btn--resume"
                  href='mailto:geri@gisgeri.com'
                >
                  Reach out
                </a>  
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
